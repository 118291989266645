<template>
	<validation-observer @submit="submit" ref="paymentMethodForm" class="w-100" tag="form">
		<section class="row text-left">
			<div class="col-12 col-lg-6 col-xl-4 my-2">
				<article class="border top-border-card border-primary p-4 rounded">
					<p class="h5 mb-4 font-weight-bold">
						<font-awesome-icon icon="fa-solid fa-money-bill" class="mr-2" size="sm" />
						Escolher forma de pagamento
					</p>

					<ul class="list-group mb-4">
						<label class="list-group-item" aria-current="true" v-if="showCreditCard">
							<b-form-radio
								:disabled="loading"
								v-model="method"
								name="method"
								value="credit_card"
							>
								<font-awesome-icon
									icon="fa-solid fa-credit-card"
									class="mx-3"
									size="xl"
								/>
								<small>Cartão de Crédito</small>
							</b-form-radio>
						</label>
						<!-- <label class="list-group-item" aria-current="true">
							<b-form-radio
								:disabled="loading"
								v-model="method"
								name="method"
								value="debit_card"
							>
								<font-awesome-icon
									icon="fa-solid fa-credit-card"
									class="mx-3"
									size="xl"
								/>
								<small>Cartão de Débito</small>
							</b-form-radio>
						</label> -->
						<label class="list-group-item" aria-current="true" v-if="showTicket">
							<b-form-radio
								:disabled="loading"
								v-model="method"
								name="method"
								value="boleto"
							>
								<font-awesome-icon
									icon="fa-solid fa-barcode"
									class="mx-3"
									size="xl"
								/>
								<small>Boleto</small>
							</b-form-radio>
						</label>
					</ul>

					<div v-if="['credit_card', 'debit_card'].includes(method)">
						<p class="mb-0 font-weight-bold">Informações do Cartão</p>
						<section class="row">
							<div class="col-12 col-lg-6 py-2">
								<validation-provider #default="{ failed }" rules="required">
									<input
										:class="`form-control ${failed && 'is-invalid'}`"
										type="text"
										class="form-control"
										placeholder="Titular"
										v-model="card.holder_name"
										name="holder_name"
										@input="removeAccents()"
										:disabled="loading"
									/>
								</validation-provider>
							</div>
							<div class="col-12 col-lg-6 py-2">
								<validation-provider
									#default="{ failed }"
									rules="min:14"
									v-mask="['###.###.###-##', '##.###.###/####-##']"
								>
									<input
										:class="`form-control ${failed && 'is-invalid'}`"
										type="text"
										class="form-control"
										placeholder="CPF/CNPJ"
										v-model="card.holder_document"
										name="holder_document"
										:disabled="loading"
										required
									/>
								</validation-provider>
							</div>
							<div class="col-12 py-2">
								<validation-provider
									#default="{ failed }"
									rules="required|min:16"
									v-mask="[
										'#### #### #### ####',
										'#### ###### ####',
										'#### ###### #####'
									]"
								>
									<input
										:class="`form-control ${failed && 'is-invalid'}`"
										type="text"
										class="form-control"
										placeholder="Número do cartão"
										v-model="card.number"
										name="number"
										:disabled="loading"
									/>
								</validation-provider>
							</div>
							<div class="col-12 col-lg-6 py-2">
								<validation-provider #default="{ failed }" rules="required|min:3">
									<input
										:class="`form-control ${failed && 'is-invalid'}`"
										class="form-control"
										placeholder="CVV"
										:disabled="loading"
										v-mask="['###', '####']"
										v-model="card.cvv"
										name="cvv"
									/>
								</validation-provider>
							</div>
							<div class="col-12 col-lg-6 py-2">
								<div class="input-group row m-0">
									<validation-provider
										#default="{ failed }"
										rules="required|between:1,12|min:2"
										slim
									>
										<input
											:class="`form-control ${failed && 'is-invalid'}`"
											type="text"
											class="form-control"
											placeholder="Mês"
											v-mask="'##'"
											v-model="card.exp_month"
											:disabled="loading"
											name="exp_month"
										/>
									</validation-provider>
									<validation-provider
										#default="{ failed }"
										:rules="`required|between:${moment().format(
											'YY'
										)},99|min:2`"
										slim
									>
										<input
											:class="`form-control ${failed && 'is-invalid'}`"
											type="text"
											class="form-control"
											placeholder="Ano"
											v-mask="'##'"
											:disabled="loading"
											v-model="card.exp_year"
											name="exp_year"
										/>
									</validation-provider>
								</div>
							</div>
						</section>
					</div>
				</article>
			</div>

			<div class="col-12 col-lg-6 col-xl-4 my-2">
				<article class="border top-border-card border-primary p-4 rounded">
					<p class="h5 mb-4 font-weight-bold">
						<font-awesome-icon icon="fa-solid fa-address-book" class="mr-2" size="sm" />
						Endereço de cobrança
					</p>

					<section class="row">
						<div class="col-5 py-2">
							<validation-provider #default="{ failed }" rules="required|min:9">
								<input
									:class="`form-control ${failed && 'is-invalid'}`"
									type="text"
									class="form-control"
									placeholder="CEP"
									:disabled="loading"
									v-mask="'#####-###'"
									v-model="card.billing_address.zip_code"
									name="zip_code"
								/>
							</validation-provider>
						</div>
						<div class="col-7 py-2">
							<validation-provider #default="{ failed }" rules="required">
								<select
									:class="`form-control ${failed && 'is-invalid'}`"
									v-model="card.billing_address.country"
									:disabled="loading"
									name="country"
								>
									<option :value="null" hidden selected>País</option>
									<option
										v-for="country in Object.keys(countries)"
										:key="`state-option-${country}`"
										:value="country"
									>
										{{ countries[country] }}
									</option>
								</select>
							</validation-provider>
						</div>
						<div class="col-4 py-2">
							<validation-provider #default="{ failed }" rules="required">
								<select
									:class="`form-control ${failed && 'is-invalid'}`"
									v-model="card.billing_address.state"
									name="state"
									:disabled="loading"
								>
									<option :value="null" hidden selected>UF</option>
									<option
										v-for="state in Object.keys(states)"
										:key="`state-option-${state}`"
										:value="state"
									>
										{{ state }}
									</option>
								</select>
							</validation-provider>
						</div>
						<div class="col-8 py-2">
							<validation-provider #default="{ failed }" rules="required">
								<input
									:class="`form-control ${failed && 'is-invalid'}`"
									type="text"
									class="form-control"
									placeholder="Cidade"
									v-model="card.billing_address.city"
									:disabled="loading"
								/>
							</validation-provider>
						</div>
						<div class="col-12 py-2">
							<validation-provider #default="{ failed }" rules="required">
								<input
									:class="`form-control ${failed && 'is-invalid'}`"
									type="text"
									class="form-control"
									placeholder="Bairro"
									name="neighborhood"
									v-model="card.billing_address.address.district"
									:disabled="loading"
								/>
							</validation-provider>
						</div>
						<div class="col-12 py-2">
							<validation-provider #default="{ failed }" rules="required">
								<input
									:class="`form-control ${failed && 'is-invalid'}`"
									type="text"
									class="form-control"
									placeholder="Endereço"
									v-model="card.billing_address.address.street"
									:disabled="loading"
								/>
							</validation-provider>
						</div>
						<div class="col-4 py-2">
							<validation-provider #default="{ failed }" rules="required">
								<input
									:class="`form-control ${failed && 'is-invalid'}`"
									type="number"
									class="form-control"
									placeholder="Número"
									v-model="card.billing_address.address.number"
									:disabled="loading"
								/>
							</validation-provider>
						</div>
						<div class="col-8 py-2">
							<input
								class="form-control"
								type="text"
								placeholder="Complemento"
								name="complement"
								v-model="card.billing_address.complement"
								:disabled="loading"
							/>
						</div>
					</section>
				</article>
			</div>

			<div class="col-12 col-xl-4 my-2">
				<article class="border top-border-card border-primary p-4 rounded">
					<p class="h5 mb-4">
						<font-awesome-icon icon="fa-solid fa-list" class="mr-2" size="sm" />
						Revisão
					</p>

					<div class="row">
						<!-- <div class="d-flex justify-content-end align-items-end">
							<span class="mr-3">Total:</span>
							<h3 class="text-blue fs-1 m-0">{{ user.plan.price }}</h3>
							<small>/mês</small>
						</div> -->
						<div class="col-12 mb-4">
							<ul class="list-group">
								<li class="list-group-item">
									<div class="d-flex flex-column">
										<p class="mb-1">
											<b>Plano {{ plan.name }}</b>
											({{ planType }})
										</p>
										<span class="text-blue fs-4">
											{{ currencyFormatter.format(installmentPrice) }}
											<small>/mês</small>
										</span>
                                        <p class="mb-1" v-if="externalDiscount > 0">
											<b>Valor subtraído</b>
										</p>
                                        <span class="text-blue fs-4" v-if="externalDiscount > 0">
											{{ currencyFormatter.format(externalDiscount) }}
										</span>
                                        <p class="mb-1" v-if="extraValue > 0">
											<b>Adicionais do plano</b>
                                            ({{ planType }})
										</p>
										<span class="text-blue fs-4" v-if="extraValue > 0">
											{{ currencyFormatter.format(extraValue) }}
											<small>/mês</small>
										</span>

                                        <p class="mb-1" v-if="extraValueNow > 0">
											<b>Adicionais de agora</b>

										</p>
										<span class="text-blue fs-4" v-if="extraValueNow > 0">
											{{ currencyFormatter.format(extraValueNow) }}
										</span>

									</div>
								</li>
							</ul>
						</div>

						<div v-if="method == 'credit_card'" class="col-12 mb-3">
							<div class="input-group">
								<select
									class="form-control"
									v-model="installments"
									:disabled="loading"
									name="installments"
								>
									<option
										v-for="installments in planSize"
										:key="installments"
										:value="installments"
									>
										{{ installments }}x de
										{{ currencyFormatter.format((total/installments)) }}
									</option>
								</select>
							</div>
						</div>

						<div v-if="coupon == null" class="col-12 mb-3">
							<div class="input-group">
								<input
									v-model="couponCode"
									type="text"
									max="24"
									min="0"
									class="form-control"
									placeholder="Inserir cupom"
									:disabled="loading"
								/>
								<button
									:disabled="loading"
									@click="validateCoupon"
									class="btn btn-outline-secondary"
									type="button"
								>
									Adicionar
								</button>
							</div>
						</div>

						<div v-else class="col-12 mb-3">
							<div class="input-group">
								<input
									v-model="coupon.code"
									type="text"
									max="24"
									min="0"
									class="form-control"
									readonly
								/>
								<span
									class="input-group-text bg-white d-flex justify-content-center"
									style="width: 33px"
									role="button"
									title="Remover cupom"
									@click="() => (coupon = null)"
								>
									<font-awesome-icon
										icon="fas fa-close"
										role="button"
										size="xs"
									/>
								</span>
							</div>
						</div>

						<div class="col-12 mb-2 text-right d-flex flex-column">
							<span
								v-if="discount > 0"
								class="d-flex justify-content-between align-items-center"
							>
								<span>Subtotal:</span>
								<span class="text-nowrap">
									{{ currencyFormatter.format(subtotal) }}
								</span>
							</span>

							<span
								v-if="discount > 0"
								class="d-flex justify-content-between align-items-center"
							>
								<span class="text-nowrap">Desconto:</span>
								<span class="text-nowrap">
									-{{ currencyFormatter.format(discount) }}
								</span>
							</span>

							<hr v-if="discount > 0" class="border border-secondary w-100 my-2" />

							<span class="d-flex justify-content-between align-items-center">
								<span>Total:</span>
								<span class="text-nowrap">
									<b class="lead font-weight-bold">
										{{ currencyFormatter.format(total) }}
									</b>
									<small v-if="!coupon || (coupon && coupon.applicable_renewal)">
										/{{ planTypeOrdinal }}
									</small>
								</span>
							</span>
						</div>

						<div v-if="coupon && !coupon.applicable_renewal" class="col-12 mb-2">
							<small>
								Desconto válido na primeira compra, após isso será cobrado
								<b>{{ currencyFormatter.format(subtotal) }}</b>
								por {{ planTypeOrdinal }}.
							</small>
						</div>

						<div class="col-12 mb-2">
							<small class="m-0">
								Ao clicar em "Confirmar assinatura", eu concordo com os
								<a href="#" target="_blank">Termos e Condições</a>
								e as
								<a href="https://convo4u.com/privacy-policies" target="_blank">
									Políticas de Privacidade.
								</a>
							</small>
						</div>
						<div class="col-12">
							<button
								:disabled="loading"
								@click="submit"
								class="btn btn-primary w-100"
								type="button"
							>
								Confirmar assinatura
							</button>
						</div>
					</div>
				</article>
			</div>
		</section>
	</validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, min, between } from "@validations";
import {
	states,
	countries,
	planTypes,
	planTypesSize,
	planTypesOrdinal,
	currencyFormatter
} from "@/api/constants";
import { BFormRadio } from "bootstrap-vue";
import axios from "axios";
import api from "@/api";

export default {
	components: {
		ValidationProvider,
		ValidationObserver,
		BFormRadio
	},
	data() {
		return {
			currencyFormatter,
			required,
			min,
			between,
			innerLoading: false,
			method: "credit_card",
			couponCode: null,
			coupon: null,
			installments: 1,

			card: {
				holder_document: null,
				exp_month: null,
				exp_year: null,
				cvv: null,
				billing_address: {
					complement: null,
					zip_code: null,
					city: null,
					state: null,
					country: "BR",
					address: {
						street: null,
						number: null,
						district: null
					}
				}
			},
			states,
			countries
		};
	},
	computed: {
		fullAddress() {
			const { street, number, district } = this.card.billing_address.address;
			const array = [number, street, district];
			return array.join(", ");
		},
		planType() {
			return this.type in planTypes ? planTypes[this.type] : planTypes["monthly"];
		},
		planSize() {
			return this.type in planTypesSize ? planTypesSize[this.type] : planTypesSize["monthly"];
		},
		planTypeOrdinal() {
			return this.type in planTypesOrdinal
				? planTypesOrdinal[this.type]
				: planTypesOrdinal["monthly"];
		},
        extraPhonesPlan() {
            if (!('extra_phone_numbers' in this.user)) return 0;
            const value = this.extraValues.additional_phone_price * this.user.extra_phone_numbers
            return value
        },
        extraAgentsPlan() {
            if (!('extra_agents' in this.user)) return 0;
            const value = this.extraValues.additional_agent_price * this.user.extra_agents
            return value
        },
		planPrice() {
			if (!this.plan.prices || !(this.type in this.plan.prices)) return null;

			const value = this.plan.prices[this.type];

			if (!value) return null;

			return value;
		},
		installmentPrice() {
			if (this.method != 'credit_card') return this.planPrice

			return this.extraValue == 0 && this.extraValueNow == 0 ?
            this.total / this.installments :
            this.subtotal / this.installments
		},
		loading() {
			return this.innerLoading || this.disabled;
		},
		subtotal() {
			return this.planSize * this.planPrice + this.extraAgentsPlan + this.extraPhonesPlan;
		},
		discount() {
			if (!this.coupon || this.coupon.discount <= 0) {
				return 0;
			}

			const discountType = this.coupon.discount_type ?? "fixed";
			const discount = this.coupon.discount ?? 0;
			let discountValue = 0;

			if (discountType == "fixed") {
				discountValue =  Number(discount);
			} else if (discountType == "percent") {
				discountValue = (discount / 100) * this.subtotal;
			}

			if (discountValue > this.subtotal) {
				discountValue = this.subtotal;
			}

			return discountValue;
		},
		total() {
			return (this.subtotal + this.extraValue + this.extraValueNow - this.externalDiscount) - this.discount;
		}
	},
	props: {
		disabled: {
			type: Boolean,
			default: () => false
		},
		plan: {
			type: Object,
			required: false
		},
		type: {
			type: String,
			default: () => "monthly"
		},
        extraValue: {
            type: Number,
            default: 0,
            required: false
        },
        extraValueNow: {
            type: Number,
            default: 0,
            required: false
        },
        showCreditCard: {
            type: Boolean,
            default: true
        },
        showTicket: {
            type: Boolean,
            default: true
        },
        externalDiscount: {
            type: Number,
            default: 0,
            required: false
        }
	},
	methods: {
		removeAccents() {
			this.card.holder_name = this.card.holder_name
				.normalize("NFD")
				.replace(/[\u0300-\u036f]/g, "");
		},
		toast(message, type = "danger") {
			this.$root.$bvToast.toast(message, {
				title: "Checkout",
				autoHideDelay: 2000,
				variant: type
			});
		},
        validateCoupon() {
            if (
				!this.couponCode ||
				this.couponCode.lenght > 24 ||
				!/[0-9A-Z]/.test(this.couponCode)
			) {
				return this.toast("Insira um código promocional válido");
			}
            this.innerLoading = true;
            api.get(`coupons/${this.couponCode}`, { params: { type: "first" } })
				.then((res) => {
					const { body, type } = res.data;

					if (type == "success") {
						this.couponCode = null;
						this.coupon = body;
                        console.log(this.coupon)
					} else {
						throw res;
					}
				})
				.catch(() => {
					this.toast("Insira um código promocional válido");
					this.coupon = null;
				})
				.finally(() => (this.innerLoading = false));

        },
		getTokenCard() {
			const url =
				"https://api.pagar.me/core/v5/tokens?appId=" +
				process.env.VUE_APP_PAGARME_PUBLIC_KEY;

			return axios.post(url, {
				type: "card",
				card: {
					number: this.card.number.replace(/\s/g, ""),
					holder_name: this.card.holder_name,
					holder_document: this.card.holder_document.replace(/\D/g, ""),
					exp_month: this.card.exp_month,
					exp_year: this.card.exp_year,
					cvv: this.card.cvv
				}
			})
		},
		async submit() {
			const validate = await this.$refs.paymentMethodForm.validate();
			if (!validate) return;

			this.innerLoading = true;

			const address = {
				line_1: this.fullAddress,
				line_2: this.card.billing_address.complement,
				city: this.card.billing_address.city,
				state: this.card.billing_address.state,
				country: this.card.billing_address.country,
				zip_code: this.card.billing_address.zip_code.replace(/\D/g, "")
			};

			if (this.method === "credit_card" || this.method === "debit_card") {
				this.getTokenCard()
					.then(( {data} ) => {
						const card_token = data.id;
						const installments = this.method != 'credit_card' ? 1 : this.installments;

						this.$emit("submit", this.method, address, card_token, this.coupon ? this.coupon._id : null, installments);
					})
					.catch(( {response} ) => {
                        console.log('response =>', response)
                        if (response != undefined) {
                            const errors = response.data.errors;
                            if (errors) {
                                Object.keys(errors).forEach((error) => {
                                    console.log(errors[error][0])
                                    this.toast(errors[error][0]);
                                });
                            }
                        }

					})
					.finally(() => (this.innerLoading = false));
			} else if (this.method === "boleto") {
				this.$emit("submit", this.method, address, null, this.coupon ? this.coupon._id : null);

				this.innerLoading = false;
            }
			if (
				!this.couponCode ||
				this.couponCode.lenght > 24 ||
				!/[0-9A-Z]/.test(this.couponCode)
			) {
				return this.toast("Insira um código promocional válido");
			}

			this.innerLoading = true;

			api.get(`coupons/${this.couponCode}`, { params: { type: "first" } })
				.then((res) => {
					const { body, type } = res.data;

					if (type == "success") {
						this.couponCode = null;
						this.coupon = body;
					} else {
						throw res;
					}
				})
				.catch(() => {
					this.toast("Insira um código promocional válido");
					this.coupon = null;
				})
				.finally(() => (this.innerLoading = false));
		}
	}
}
</script>
