<template>
	<section class="position-relative px-4 pb-3">
		<checkout-form
			class="my-4"
			:plan="this.user.plan"
			:type="this.user.plan_type ?? 'monthly'"
			@submit="submit"
			:disabled="loading"
		/>
	</section>
</template>

<script>
import CheckoutForm from "./components/CheckoutForm.vue";

import api from "@/api";

export default {
	data() {
		return {
			url: null,
			loading: false
		};
	},
	components: {
		CheckoutForm
	},
	methods: {
		toast(message, type = "danger") {
			this.$root.$bvToast.toast(message, {
				title: "Checkout",
				autoHideDelay: 2000,
				variant: type
			});
		},
		submit(paymentMethod, address, cardToken = undefined, couponId = undefined, installments = 1) {

			this.loading = true;

			api.post("/purchases", {
				card_token: cardToken,
				payment_method: paymentMethod,
				address,
				coupon_id: couponId,
				installments
			})
				.then(({ data }) => {
					const { body, type } = data;

					if (body && body.status === "paid") {
						this.toast("Assinatura realizada com sucesso!", "success");

						return this.$store.dispatch("user/loadSession");
					} else if (type != "success") {
						this.toast(
							data.message ?? "Não foi possível realizar a assinatura.",
							"danger"
						);
					}

					this.$store.dispatch("user/loadSession");
				})
				.catch((error) => {
					this.toast(
						"Não foi possível criar o cartão. A verificação do cartão falhou.",
						"danger"
					);
					throw error
				})
				.finally(() => (this.loading = false));
		}
	}
};
</script>
