<template>
    <b-modal
        :id="id"
        body-class="position-unset"
        centered
        content-class="p-4 position-relative"
        footer-border-variant="light"
        header-border-variant="light"
        title="Inserir variável"
        lazy
        @hide="reset"
    >
        <loading v-if="loading" />

        <validation-observer ref="formMacroForm">
            <validation-provider
                #default="{ failed }"
                rules="required"
            >
                <select
                    @change="getFields"
                    class="form-control"
                    :class="{'is-invalid': failed}"
                    v-model="formSelected"
                >
                    <option :value="null">Selecione o formulário</option>
                    <option
                        v-for="(form, i) in forms"
                        :key="`form-option-${i}`"
                        :value="form._id"
                    >
                        {{ form.name }}
                    </option>
                </select>
            </validation-provider>

            <validation-provider
                #default="{ failed }"
                rules="required"
            >
                <select
                    :disabled="fields.length == 0"
                    class="form-control my-4"
                    :class="{'is-invalid': failed}"
                    v-model="fieldSelected"
                >
                    <option :value="null">Selecione o campo</option>
                    <option
                        v-for="(field, i) in fields"
                        :key="`form-option-${i}`"
                        :value="field"
                    >
                        {{ field }}
                    </option>
                </select>
            </validation-provider>


            <validation-provider
                #default="{ failed }"
                :rules="{
                    regex: /^([\w\sÀ-ÿ]+)$/
                }"
            >
                <input
                    type="text"
                    class="form-control"
                    :class="{'is-invalid': failed}"
                    v-model="defaultField"
                    placeholder="Valor padrão"
                >
            </validation-provider>
        </validation-observer>

        <template #modal-footer="{ cancel, ok }">
            <button :disabled="loading" class="btn btn-outline-primary px-5 mr-2" @click="cancel">
                Cancelar
            </button>
            <button :disabled="loading" @click="submit(ok)" class="btn btn-primary px-5">
                Inserir
            </button>
        </template>
    </b-modal>
</template>

<script>
import {BModal} from "bootstrap-vue";
import Loading from '@/views/components/Loading.vue';
import api from "@/api";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { required, regex } from "@/@core/utils/validations/validations";

export default {
    components: {
        BModal,
        Loading,
        ValidationObserver,
        ValidationProvider
    },
    props: ['id'],
    data() {
        return {
            forms: [],
            fields: [],
            formSelected: null,
            fieldSelected: null,
            defaultField: null,
            required,
            regex,
            loading: false
        };
    },
    mounted() {
      this.getForms();
    },
    methods: {
        toast(message, type = 'danger') {
            if (type == 'error') type = 'danger';
            this.$bvToast.toast(message, {title: 'Comentários', variant: type})
        },
        reset() {
            this.formSelected = null;
            this.fieldSelected = null;
            this.defaultField = null;
            this.fields = [];
        },
        getForms() {
            this.loading = true;
            this.$store.dispatch('form/index', {
                auth: this.$store.getters["user/getAuth"],
                with_customers: false
            })
            .then(() => {
                this.forms = this.$store.getters["form/get"];
            })
            .finally(() => this.loading = false)
        },
        getFields() {
            this.fields = [];

            if(this.formSelected == null) return;

            this.loading = true;
            api.get('/forms/fields', {
                params: {
                    form_id: this.formSelected
                }
            })
            .then(response => {
                this.fields = response.data.body;
            })
            .finally(() => this.loading = false)
        },
        submit(closeHandler) {
            this.$refs.formMacroForm.validate().then(success => {
                if(!success) return;

                let macro = `%%Formulario:${this.fieldSelected}%%`;

                if(this.defaultField) {
                    macro = `%%Formulario:${this.fieldSelected},${this.defaultField}%%`;
                }
                this.$emit('input', macro);
                closeHandler()
            })
        },
    },
}
</script>
