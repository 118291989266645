<template>
	<b-modal
		no-close-on-backdrop
		id="infoTrayModal"
		v-model="visible"
		centered
		title="Integrar Tray"
	>
		<p>Para realizar a conexão com a Tray, siga uma das etapas abaixo:</p>
		<h5>Forneça o domínio de sua loja</h5>
		<validation-observer ref="domainTrayForm">
			<form>
				<div class="form-group">
					<validation-provider
						#default="{ errors }"
						name="domínio"
						:rules="{ regex: /^[a-z0-9-.àáâãéêíóôõúüç]+\.[a-z0-9-àáâãéêíóôõúüç]+$/ }"
					>
						<div class="d-flex">
							<input
								v-model="trayDomain"
								:class="`form-control ${errors.length > 0 && 'is-invalid'}`"
								required
								type="text"
								class="form-control flex-grow-1"
								placeholder="Ex: minhalojanatray.com.br"
							/>
							<button
								:disabled="!trayDomain || errors.length != 0 || loading.domain"
								class="btn btn-primary ml-2 d-flex align-items-center"
								@click.prevent="handleRedirectTrayAuth"
							>
								<span
									class="spinner-border spinner-border-sm mr-1"
									v-if="loading.domain"
									role="status"
									aria-hidden="true"
								></span>
								Redirecionar
							</button>
						</div>
					</validation-provider>
				</div>
			</form>
		</validation-observer>
		<p class="text-center">ou</p>
		<h5>Siga o passo-a-passo</h5>
		<ol class="pl-3">
			<li>
				Acesse o painel administrativo da sua loja
				<a href="https://www.tray.com.br/login/" target="_blank">Tray</a>.
			</li>
			<li>
				Vá para a seção "<b>Aplicativos</b>".
			</li>
			<li>
				Clique em "<b>Instalar Novos Aplicativos</b>".
			</li>
			<li>
				Na barra de pesquisa, digite "<b>Convo4u</b>" 
				e clique na opção correspondente.
			</li>
			<li>
				Clique em "<b>Instalar Aplicativo</b>".
			</li>
			<li>
				Caso você não seja redirecionado automaticamente e apareça
				uma tela informando os <b>Dados da autorização</b>, insira-os abaixo:
			</li>
		</ol>
		<validation-observer ref="authTrayForm">
			<form>
				<div class="form-group">
					<validation-provider
						#default="{ errors }"
						name="url"
						:rules="{ required: true, regex: /^https:\/\/.*\/web_api$/  }"
						class="input-group"
					>
      					<label for="tray-url-input" class="input-group-text">URL</label>
						<input
							v-model="auth.url"
							:class="`form-control ${errors.length > 0 && 'is-invalid'}`"
							required
							type="text"
							id="tray-url-input"
							class="form-control flex-grow-1"
							placeholder="Ex: www.urldaloja.com.br/web_api"
						/>
					</validation-provider>
				</div>
				<div class="form-group">
					<validation-provider
						#default="{ errors }"
						name="código"
						:rules="{ required: true, regex: /^[a-fA-F0-9]{64}$/ }"
						class="input-group"
					>
      					<label for="tray-code-input" class="input-group-text">Código</label>
						<div class="d-flex flex-grow-1">
							<input
								v-model="auth.code"
								:class="`form-control ${errors.length > 0 && 'is-invalid'}`"
								required
								type="text"
								id="tray-code-input"
								class="form-control flex-grow-1"
								placeholder="Ex: 2fd3b7910b2324c83262aae..."
							/>
							<button
								:disabled="!auth.code || !auth.url || errors.length != 0 || loading.auth"
								class="btn btn-primary ml-2 d-flex align-items-center"
								@click.prevent="handleTrayAuth"
							>
								<span
									class="spinner-border spinner-border-sm mr-1"
									v-if="loading.auth"
									role="status"
									aria-hidden="true"
								></span>
								Autorizar
							</button>
						</div>
					</validation-provider>
				</div>
			</form>
		</validation-observer>
		<template #modal-footer="{ cancel }">
			<div class="d-flex align-items-center jusitfy-content-end">
				<button :disabled="loading.domain || loading.auth" @click="cancel" class="btn btn-secondary">
					Fechar
				</button>
			</div>
		</template>
	</b-modal>
</template>

<script>
import { BModal } from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import api from "@/api";

export default {
	components: {
		BModal,
		ValidationProvider,
		ValidationObserver
	},
	data() {
		return {
			trayDomain: null,
			auth: {
				url: null,
				code: null
			},
			loading: {
				domain: false,
				auth: false
			},
			visible: false
		};
	},
	methods: {
		/**
		 * Exibe um toast de acordo com a configuração, informando o tipo.
		 *
		 * @param {String} message Mensagem a ser mostrada
		 * @param {String} type Tipo da mensagem
		 * @returns {void}
		 */
		toast(message, type = "danger") {
			this.$bvToast.toast(message, {
				title: "Tray",
				autoHideDelay: 2000,
				variant: type
			});
		},
		/**
		 * Reseta o formulário, removendo os valores e tirando os erros.
		 *
		 * @returns {void}
		 */
		resetForm() {
			this.trayDomain = null;
			this.auth.code = null;
			this.auth.url = null;
			this.$refs.domainTrayForm.reset();
			this.$refs.authTrayForm.reset();
		},
		/**
		 * Valida o domínio e busca a URL de autorização do serviço.
		 *
		 * @returns {void}
		 */
		handleRedirectTrayAuth() {
			this.$refs.domainTrayForm.validate().then((success) => {
				if (!success) return;
				this.loading.domain = true;

				const params = { domain: this.trayDomain };
				api.get(`integrations/tray/authorize`, { params })
					.then((res) => {
						const { type, body } = res.data;

						if (type === "success") return (window.location.href = body);

						throw res;
					})
					.catch((e) => {
						let message = "Não foi possível estabelecer conexão com a integração";

						if (e.response) message = e.responde.data.message;
						if (e.data) message = e.data.message;

						this.toast(message);
						this.loading.domain = false;
					});
			});
		},
		/**
		 * Segunda via de autenticação, caso a primeira por redirecionamento não dê certo.
		 *
		 * @returns {void}
		 */
		 handleTrayAuth() {
			this.$refs.authTrayForm.validate().then((success) => {
				if (!success) return;
				this.loading.auth = true;

				const payload = { api_address: this.auth.url, code: this.auth.code };
				
				api.post(`integrations/tray/callback`, payload)
					.then((res) => {
						const { type, message } = res.data;

						if (type == "success") {
							this.$router.replace({
								name: "settings",
								params: {
									tab: "integracoes",
									type: "tray"
								},
							});
							return this.$bvModal.hide("infoTrayModal");
						}

						this.toast(message);
					})
					.catch((err) => {
						let message = "Não foi possível estabelecer conexão com a integração";

						if (err.response) message = err.response.data.message;
						this.toast(message);
					})
					.finally(() => (this.loading.auth = false));
			});
		}
	},
	watch: {
		visible(value) {
			if (!value) this.resetForm();
		}
	}
};
</script>
