<template>
    <div class="my-2" :id="`campaign-text-${id}`">
        <div
            :class="`${
                editable && 'border editable rounded'
            } p-3 position-relative campaign-text-container`"
        >
            <div
                role="button"
                class="bg-primary rounded-circle editable-icon d-flex align-items-center justify-content-center"
                @click="editable = true"
                v-if="!editable && !readOnly"
            >
                <font-awesome-icon size="sm" icon="fa-solid fa-pen-to-square" />
            </div>
            <small v-if="editable" class="label">Editar</small>
            <div class="text-left">
                <div class="d-flex">
                    <h5
                        class="text-dark mr-2 m-0"
                        v-if="index != null && showTitle"
                    >
                        {{ index || 0 }}.
                    </h5>
                    <div class="flex-grow-1 d-flex flex-column">
                        <!-- Título -->
                        <h5
                            @click="lastSelectedArea = 'titleCampaign'"
                            @paste.prevent="removeTagsOnPaste"
                            @mouseup="delayedShowTooltip"
                            @keydown="deleteSynonym"
                            @input="paintMacros"
                            style="word-break: break-word; display: inline"
                            :contenteditable="editable"
                            class="text-dark m-0"
                            id="title-editable"
                            ref="titleCampaign"
                            v-html="titleHTML"
                            v-if="showTitle"
                        ></h5><br>
                        <!-- /Título -->

                        <p
                            @click="lastSelectedArea = 'textCampaign'"
                            @paste.prevent="removeTagsOnPaste"
                            @mouseup="prepareSelection"
                            @input="paintMacros"
                            style="word-break: break-word; display: inline"
                            class="mt-2 m-0 text-wysiwyg-container"
                            :contenteditable="editable"
                            ref="textCampaign"
                            id="text-editable"
                            v-html="textHTML"
                            v-if="showText"
                        ></p>
                    </div>
                </div>

                <!-- Listagem dos Sinônimos -->
                <div
                    v-if="
                        campaign_text.synonyms &&
                        campaign_text.synonyms.length > 0
                    "
                >
                    <small>Sinônimos relacionados</small>
                    <div class="d-flex flex-wrap">
                        <div
                            v-for="(
                                objectSynonyms, index
                            ) in campaign_text.synonyms"
                            :key="`badges-synonyms-${index}`"
                            class="d-flex flex-wrap"
                        >
                            <h5
                                class="mr-2"
                                v-for="(
                                    synonym, indexCol
                                ) in objectSynonyms.synonyms"
                                :key="`badges-synonyms-${index}-${indexCol}`"
                            >
                                <span
                                    :style="`color: ${objectSynonyms.color}; border-color: ${objectSynonyms.color} !important;`"
                                    class="badge border border-secondary font-weight-normal"
                                >
                                    {{ synonym }}
                                </span>
                            </h5>
                        </div>
                    </div>
                </div>
                <!-- /Listagem dos Sinônimos -->

                <div v-if="editable && synonymPopovers">
                    <!-- Popover de edição dos sinônimos -->
                    <b-popover
                        v-for="synonym in synonymSpans()"
                        :key="`popover-span-${synonym.id}`"
                        :id="`popover-container-span-${synonym.id}`"
                        :target="synonym.id"
                        triggers="hover"
                        placement="right"
                    >
                        <template #title>
                            <div
                                class="d-flex align-items-center justify-content-between"
                            >
                                <span>Sinônimos</span>
                                <span
                                    role="button"
                                    class="text-danger"
                                    @click="removeSynonym(synonym.index)"
                                    >Remover</span
                                >
                            </div>
                        </template>
                        <b-form-tags
                            placeholder="Insira sinônimos..."
                            v-model="
                                campaign_text.synonyms[synonym.index].synonyms
                            "
                            separator=","
                            remove-on-delete
                            add-button-text="Adicionar"
                        ></b-form-tags>
                    </b-popover>
                    <!-- /Popover de edição dos sinônimos -->
                </div>
            </div>
        </div>

        <!-- Preview de Arquivos Anexados -->
        <div
            v-if="allowAttachments && campaign_text.attachments.length > 0"
            class="row flex-wrap px-3"
        >
            <div
                v-for="(attachment, i) in campaign_text.attachments"
                :key="`attachment-${id}-${i}`"
                class="col-6 col-sm-2 col-md-4 col-lg-3 col-xl-2 p-2"
            >
                <div class="attachment-container position-relative">
                    <font-awesome-icon
                        role="button"
                        color="red"
                        class="remove-attach"
                        icon="fa-solid fa-close"
                        @click="campaign_text.attachments.splice(i, 1)"
                        v-if="!readOnly && editable"
                    />
                    <img class="img-thumbnail" :src="attachment.thumbnail" />
                </div>
            </div>
        </div>
        <!-- /Preview de Arquivos Anexados -->

        <!-- Ações do Componente -->
        <div
            v-if="editable"
            class="d-flex editable-options align-items-center flex-wrap justify-content-center my-2"
        >
            <!-- Botão de Remover -->
            <font-awesome-icon
                v-if="showDelete"
                @click="$emit('onDelete', data)"
                class="my-1 mx-3 mx-lg-0"
                :class="{ 'mx-lg-3': !showToolbar }"
                role="button"
                size="lg"
                icon="fa-solid fa-trash-can"
                v-b-tooltip="'Remover'"
            />
            <!-- /Botão de Remover -->

            <!-- Toolbar (Barra de Ferramentas) -->
            <div
                :class="`${showDelete ? 'mx-md-3' : 'mr-md-3'} my-1`"
                v-if="showToolbar"
            >
                <div
                    class="shadow-sm tools d-flex form-control align-items-center justify-content-center"
                >
                    <font-awesome-icon
                        @mousedown="toggleTag('b')"
                        role="button"
                        color="gray"
                        class="my-1 mx-2"
                        icon="fa-solid fa-bold"
                        v-b-tooltip="'Negrito'"
                    />
                    <div class="d-flex align-items-center ml-md-3 my-1">
                        <font-awesome-icon
                            @mousedown="toggleTag('i')"
                            role="button"
                            color="gray"
                            class="mx-2"
                            icon="fa-solid fa-italic"
                            v-b-tooltip="'Itálico'"
                        />
                        <font-awesome-icon
                            @mousedown="toggleTag('s')"
                            role="button"
                            color="gray"
                            class="mx-2"
                            icon="fa-solid fa-strikethrough"
                            v-b-tooltip="'Tachado'"
                        />
                    </div>
                    <div class="d-flex align-items-center my-1">
                        <font-awesome-icon
                            :id="`popover-macro-info-${id}`"
                            role="button"
                            color="gray"
                            class="mx-2"
                            icon="fas fa-question"
                            v-b-tooltip="'Inserir variáveis'"
                        />
                        <font-awesome-icon
                            v-if="allowAttachments"
                            role="button"
                            color="gray"
                            class="mx-2"
                            icon="fas fa-link"
                            v-b-tooltip="'Encurtar link'"
                            v-b-modal.shortLinkModal
                        />
                        <label
                            class="m-0"
                            v-if="
                                allowAttachments &&
                                campaign_text.attachments.length <
                                    maxAttachments
                            "
                            :for="`input-${id}`"
                        >
                            <font-awesome-icon
                                role="button"
                                color="gray"
                                class="mx-2"
                                icon="fas fa-image"
                                v-b-tooltip="'Inserir imagem'"
                            />
                        </label>

                        <label
                            class="m-0"
                            v-if="
                                allowAttachments &&
                                campaign_text.attachments.length <
                                    maxAttachments
                            "
                            :for="`input-${id}`"
                        >
                            <font-awesome-icon
                                role="button"
                                color="gray"
                                class="mx-2"
                                icon="fa-solid fa-video"
                                v-b-tooltip="'Inserir vídeo'"
                            />
                        </label>
                        <resizable-file-input
                            :input-id="`input-${id}`"
                            v-if="allowAttachments"
                            :has-preview="false"
                            :options="{ 'aspect-ratio': 1 }"
                            @input="(e) => campaign_text.attachments.push(e)"
                        />

                        <emoji-picker
                            @emoji="appendEmoji"
                            v-b-tooltip="'Inserir emoji'"
                            class="mx-2"
                        ></emoji-picker>
                    </div>
                </div>
            </div>
            <!-- /Toolbar (Barra de Ferramentas) -->

            <!-- Popover de dica dos macros -->
            <b-popover
                v-if="showToolbar"
                :target="`popover-macro-info-${id}`"
                triggers="click blur"
                placement="top"
            >
                <template #title>
                    <b>Variáveis disponíveis:</b>
                </template>

                <ul style="list-style: none" class="p-0 m-0">
                    <li
                        class="my-1"
                        v-for="(macro, i) in macroList"
                        :key="`macro-list-${id}-${i}`"
                    >
                        <b
                            role="button"
                            @click="
                                (e) =>
                                    !macro.modal
                                        ? putMacro(e.target.innerText)
                                        : () => {}
                            "
                            :style="`color: ${macro.color};`"
                            class="text-nowrap"
                            v-b-modal="macro.modal ? `${id}-form-macro` : null"
                        >
                            {{ macro.neddle }} </b
                        >: {{ macro.description }}
                    </li>
                </ul>
            </b-popover>
            <form-macro-modal :id="`${id}-form-macro`" @input="putMacro" />
            <!-- /Popover de dica dos macros -->

            <!-- Botão de Salvar -->
            <button
                @click="() => save()"
                class="btn btn-primary flex-grow-1 my-1"
            >
                Salvar
            </button>
            <!-- /Botão de Salvar -->
        </div>
        <!-- /Ações do Componente -->
    </div>
</template>

<script>
import { BPopover, BFormTags } from "bootstrap-vue";
import { uuid } from "vue-uuid";
import props from "./props.js";
import ResizableFileInput from "@/views/components/ResizableFileInput.vue";
import FormMacroModal from "@/views/components/CardTextSynonym/FormMacroModal.vue";
import EmojiPicker from "@/views/components/Emoji.vue";

const ignoreCharacteres = "[^@\\-&_A-Za-zÀ-ÖØ-öø-ÿ0-9]";

export default {
    components: {
        BPopover,
        BFormTags,
        EmojiPicker,
        ResizableFileInput,
        FormMacroModal,
    },
    props,
    emits: ["change"],
    data() {
        return {
            editable: false,
            synonymPopovers: true,
            campaign_text: this.$props.data,
            id: uuid.v4(),
            lastSelectedArea: "textCampaign",
        };
    },
    watch: {
        /**
         * Por algum motivo o v-for fica bugado se não ficar forçando a
         * atualização aqui.
         */
        $props: {
            handler() {
                this.campaign_text = this.$props.data;
                this.$forceUpdate();
            },
            deep: true,
            immediate: true,
        },
    },
    computed: {
        /**
         * Converte o título da campanha que está apenas texto somando os sinônimos que estão
         * em um array para um título com as palavras com sinônimos envolvidas
         * por um span colorido.
         */
        titleHTML() {
            if (!this.campaign_text || !this.campaign_text.title) return "";

            let htmlTitle = String(this.campaign_text.title);

            htmlTitle = this.insertSynonyms(htmlTitle);
            htmlTitle = this.insertMacros(htmlTitle);

            return htmlTitle.trim();
        },
        /**
         * Adiciona os sinônimos, macros, bold, italic e strike no texto.
         */
        textHTML() {
            if (!this.campaign_text || !this.campaign_text.text) return "";
            let htmlText = String(this.campaign_text.text);

            htmlText = this.insertSynonyms(htmlText);
            htmlText = this.insertMacros(htmlText);

            htmlText = htmlText.replaceAll(
                /( |^)\*([^\s*]{1,}|[^\s*][^*]{1,}[^\s*])\*( |$)/g,
                " <b>$2</b> "
            );
            htmlText = htmlText.replaceAll(
                /( |^)_([^\s_]{1,}|[^\s_][^_]{1,}[^\s_])_( |$)/g,
                " <i>$2</i> "
            );
            htmlText = htmlText.replaceAll(
                /( |^)~([^\s~]{1,}|[^\s~][^~]{1,}[^\s~])~( |$)/g,
                " <s>$2</s> "
            );
            htmlText = htmlText.replaceAll(/( |&nbsp;){1,}/g, " ");
            htmlText = htmlText.replaceAll(/(\n)/g, "<br>");

            return htmlText.trim();
        },
    },
    methods: {
        appendEmoji(emoji) {
            let html = this.$refs[this.lastSelectedArea].innerHTML;
            html = html.replace(/<br>$/, "");
            this.$refs[this.lastSelectedArea].innerHTML = `${html}${emoji}`;
        },
        insertSynonyms(text) {
            if (this.campaign_text.synonyms) {
                this.campaign_text.synonyms.forEach((synonym, i) => {
                    const span = document.createElement("span");
                    span.style.color = synonym.color;
                    span.id = `synonym-${uuid.v4()}-${synonym.color}-${
                        synonym.color
                    }-${i}`;
                    span.innerHTML = synonym.sentence;
                    span.classList.add("synonym-span");
                    span.contentEditable = false;

                    text = text.replaceAll(synonym.sentence, span.outerHTML);
                });
            }
            return text;
        },
        insertMacros(text) {
            this.$props.macroList.forEach((macro) => {
                const regex = RegExp(macro.regex, "g");
                if (regex.test(text)) {
                    text = text.replaceAll(
                        regex,
                        `<font color="${macro.color}">$1</font>`
                    );
                }
            });
            return text;
        },
        save(editable = false) {
            this.$emit("change", this.campaign_text);
            this.editable = editable;
            if (this.$props.showTitle)
                this.campaign_text.title = this.$refs.titleCampaign.innerText;
            if (this.$props.showText)
                this.campaign_text.text = this.clearHTML(
                    this.$refs.textCampaign.innerHTML
                );

            if (this.campaign_text.synonyms) {
                this.campaign_text.synonyms.forEach((synonym, i) => {
                    if (
                        !(
                            String(this.campaign_text.title).includes(
                                synonym.sentence
                            ) ||
                            String(this.campaign_text.text).includes(
                                synonym.sentence
                            )
                        )
                    ) {
                        this.campaign_text.synonyms.splice(i);
                    }
                });
            }
            this.reloadPopovers();
        },
        synonymSpans() {
            const elements = document.querySelectorAll(
                `#campaign-text-${this.id} .synonym-span`
            );

            return Array.from(elements).map((e) => {
                const exp = e.id.split("-");
                return {
                    index: exp.pop(),
                    id: e.id,
                };
            });
        },
        clearHTML(text) {
            text = text.replaceAll(
                / *<b> *([^\s*]*?|[^\s*]*?[^*]*?[^\s*]*?) *<\/b> */g,
                " *$1* "
            );
            text = text.replaceAll(
                / *<i> *([^\s_]*?|[^\s_]*?[^_]*?[^\s_]*?) *<\/i> */g,
                " _$1_ "
            );
            text = text.replaceAll(
                / *<s> *([^\s~]*?|[^\s~]*?[^~]*?[^\s~]*?) *<\/s> */g,
                " ~$1~ "
            );
            text = text.replaceAll(/<div>(.*?)<\/div>/g, "<br>$1");
            text = text.replaceAll(/<br>|<br\/>/g, "\n");
            const p = document.createElement("p");
            p.innerHTML = text;
            text = p.innerText.trim();
            text = text.replaceAll(/ {1,}/g, " ");

            return p.innerText.trim();
        },
        /**
         * Transforma o título em um id
         */
        getSynonymSlug(synonym) {
            const normalized = String(synonym.sentence)
                .toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "");
            const randomString = Date.now().toString(16);
            const id = `synonym-${randomString}-${btoa(normalized)}`;

            const index = this.campaign_text.synonyms.findIndex(
                (e) => e == synonym
            );

            if (this.campaign_text.synonyms[index].id == undefined) {
                this.campaign_text.synonyms[index].id = id;
                return id;
            }
            return this.campaign_text.synonyms[index].id;
        },
        /**
         * Ativa ou desativa a tag no texto selecionado.
         */
        toggleTag(tagName) {
            const node = this.getSelectedNode();

            const bold = document.createElement(String(tagName).toLowerCase());
            this.toggleSurroundSelection(
                bold,
                !(node && node.tagName == String(tagName).toUpperCase())
            );
            if (this.$props.showText)
                this.campaign_text.text = this.clearHTML(
                    this.$refs.textCampaign.innerHTML
                );
        },
        /**
         * Envolve a seleção com um elemento especificado ou remove o
         * elemento já existente.
         */
        toggleSurroundSelection(element, toggle = true) {
            const selection = window.getSelection();
            let insertable = false;

            document
                .querySelectorAll(".text-wysiwyg-container")
                .forEach((e) => {
                    if (e.contains(selection.anchorNode)) insertable = true;
                });

            if (!insertable) return;

            if (toggle) {
                const range = selection.getRangeAt(0).cloneRange();
                element.appendChild(range.extractContents());
                return range.insertNode(element);
            }

            const node = this.getSelectedNode();
            var pa = node.parentNode;
            while (node.firstChild) {
                pa.insertBefore(node.firstChild, node);
            }
            node.remove();
        },
        /**
         * Pega o elemento que está em volta da seleção.
         */
        getSelectedNode() {
            if (document.selection)
                return document.selection.createRange().parentElement();
            else {
                var selection = window.getSelection();
                if (selection.rangeCount > 0)
                    return selection.getRangeAt(0).startContainer.parentNode;
            }
        },
        /**
         * Remove um sinônimo da lista.
         */
        removeSynonym(index) {
            this.campaign_text.synonyms.splice(index, 1);
            this.reloadPopovers();
        },
        /**
         * Aqui temos que 'atualizar' os popovers para eles funcionarem.
         */
        reloadPopovers() {
            this.synonymPopovers = false;
            setTimeout(() => (this.synonymPopovers = true), 100);
        },
        /**
         * Adiciona um sinônimo à lista com cores aleatórias.
         */
        addSynonym(selection) {
            const sentence = selection.toString().trim();
            const existingColors = this.campaign_text.synonyms.map(
                (e) => e.color
            );
            let color = this.generateLightColorHex();

            while (existingColors.includes(color))
                color = this.generateLightColorHex();

            this.save(true);

            this.campaign_text.synonyms.push({ sentence, color, synonyms: [] });
            this.reloadPopovers();
        },
        /**
         * Esse código faz algo que nem eu que fiz sei oque é, se alguém souber
         * uma forma melhor de fazer esse tooltip ao selecionar refatore esse
         * pedaço.
         */
        prepareSelection() {
            if (!this.editable) return;

            var top = 0;
            var left = 0;
            var selection = "";

            if (window.getSelection) selection = window.getSelection();
            else selection = "";

            /**
             * Verifica se a seleção pode ser transformada em um sinônimo.
             */
            const selectedText = selection.toString().trim();
            const regex = `^${selectedText}${ignoreCharacteres}|(${ignoreCharacteres}${selectedText}${ignoreCharacteres})|${ignoreCharacteres}${selectedText}$|^${selectedText}$`;

            if (
                selectedText.trim().length < 4 ||
                selectedText.replaceAll(/[@\-&_A-Za-zÀ-ÖØ-öø-ÿ0-9 ]/g, "")
                    .length > 0 ||
                selection.anchorNode.data.search(new RegExp(regex, "g")) < 0 ||
                selection.anchorNode.parentNode.nodeName == "SPAN"
            ) {
                const tooltip = document.querySelector(".selection");
                if (tooltip) tooltip.remove();
                return;
            }

            const onTooltipClick = () => {
                document.querySelector(".selection").remove();
                this.addSynonym(selection);
            };

            function setTooltipPosition() {
                const position = selection
                    .getRangeAt(0)
                    .getBoundingClientRect();
                const DOCUMENT_SCROLL_TOP =
                    window.pageXOffset ||
                    document.documentElement.scrollTop ||
                    document.body.scrollTop;
                top = position.top + DOCUMENT_SCROLL_TOP;
                left = position.left + position.width / 2;
            }

            function moveTooltip() {
                setTooltipPosition();
                let tooltip = document.querySelector(".selection");
                tooltip.style.top = `${top}px`;
                tooltip.style.left = `${left}px`;
            }

            /**
             * Desenha o tooltip na tela.
             */
            function drawTooltip() {
                setTooltipPosition();

                const div = document.createElement("div");
                div.className = "selection";
                div.innerHTML = `<span class='badge tooltip-synonym' role='button'>Adicionar sinônimo</span>`;
                div.onmousedown = onTooltipClick;
                div.style = `
                    transform: translate(-50%, -120%);
                    line-height: 0;
                    position: absolute;
                    top: ${top}px;
                    left: ${left}px;
                    transition: all 0.2s ease-in-out 0s;
                    box-shadow: rgb(0 0 0 / 25%) 0px 14px 28px, rgb(0 0 0 / 22%) 0px 10px 10px;
                    z-index: 99999;
                `;

                document.body.appendChild(div);
            }

            /**
             * Quando o usuário digitar algo o tooltip esconde.
             */
            function hideTooltipOnKeyDown() {
                const el = document.querySelector(".selection");

                if (el) {
                    el.remove();
                    document.removeEventListener(
                        "mousedown",
                        hideTooltipOnMouseDown
                    );
                    document.removeEventListener(
                        "keydown",
                        hideTooltipOnKeyDown
                    );
                }
            }

            function hideTooltipOnMouseDown() {
                const el = document.querySelector(".selection");

                if (el) {
                    el.remove();
                    document.removeEventListener(
                        "mousedown",
                        hideTooltipOnMouseDown
                    );
                    document.removeEventListener(
                        "keydown",
                        hideTooltipOnKeyDown
                    );
                }
            }

            document.removeEventListener("mousedown", hideTooltipOnMouseDown);
            document.removeEventListener("keydown", hideTooltipOnKeyDown);

            if (document.querySelector(".selection")) {
                return moveTooltip();
            }

            drawTooltip();
            document.addEventListener("mousedown", hideTooltipOnMouseDown);
            document.addEventListener("keydown", hideTooltipOnKeyDown);
        },
        deleteSynonym(event) {
            if (event.which == 8) {
                const s = window.getSelection();
                const r = s.getRangeAt(0);
                const el = r.startContainer.parentElement;
                if (el.nodeName == "SPAN") {
                    if (
                        r.startOffset == r.endOffset &&
                        r.endOffset == el.textContent.length
                    ) {
                        event.preventDefault();
                        if (el.classList.contains("highlight")) {
                            if (this.$props.showTitle)
                                this.campaign_text.title =
                                    this.$refs.titleCampaign.innerText;
                            el.remove();
                        } else {
                            el.classList.add("highlight");
                        }
                        return;
                    }
                }
            }

            event.target
                .querySelectorAll("span.label.highlight")
                .forEach(function (el) {
                    el.classList.remove("highlight");
                });
        },
        /**
         * Gera cores em hexadecimal claras.
         */
        generateLightColorHex() {
            let color = "#";
            for (let i = 0; i < 3; i++)
                color += (
                    "0" +
                    Math.floor(
                        ((1 + Math.random()) * Math.pow(16, 2)) / 2
                    ).toString(16)
                ).slice(-2);
            return color;
        },
        /**
         * Insere o macro no texto.
         */
        async putMacro(macro) {
            try {
                let html = this.$refs[this.lastSelectedArea].innerHTML;
                html = html.replace(/<br>$/, "");
                this.$refs[
                    this.lastSelectedArea
                ].innerHTML = `${html} ${macro}`;
            } catch ($e) {
                this.$toast("Não foi possível inserir a variável.", {
                    type: "danger",
                });
            }
        },
        paintMacros(event) {
            const elem = event.target;
            let oldHtml = elem.innerHTML;
            let html = elem.innerHTML;

            html = html.replaceAll(/<.?font.?(color="[\w#]*")?>/g, "");

            html = this.insertMacros(html);

            if (html != oldHtml) {
                event.target.innerHTML = html;
                const selection = window.getSelection();
                const range = document.createRange();
                selection.removeAllRanges();
                range.selectNodeContents(elem);
                range.collapse(false);
                selection.addRange(range);
                elem.focus();
            }
        },
        delayedShowTooltip() {
            setTimeout(() => this.prepareSelection(), 50);
        },
        removeTagsOnPaste(event) {
            let paste = (event.clipboardData || window.clipboardData).getData(
                "text"
            );
            const p = document.createElement("p");
            p.innerHTML = paste;
            const text = p.innerText.trim();
            const selection = window.getSelection();
            if (!selection.rangeCount) return;
            selection.deleteFromDocument();
            selection.getRangeAt(0).insertNode(document.createTextNode(text));
        },
    },
};
</script>

<style lang="scss">
[contenteditable] {
    outline: 0px solid transparent;
}

span.highlight {
    background: #e1ecf4;
    border: 1px dotted #39739d;
}

.tooltip-synonym {
    background-color: black;
    color: white;
    padding: 8px;

    &::after {
        content: " ";
        position: absolute;
        top: 100%; /* At the bottom of the tooltip */
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: black transparent transparent transparent;
    }
}

.editable {
    border-color: #ddd;

    .label {
        background: white;
        position: absolute;
        top: 0;
        transform: translateY(-50%);
        left: 9px;
        padding: 0 5px;
    }
}

.editable-options {
    .tools {
        border-color: #ddd;
    }
}

.campaign-text-container {
    .editable-icon {
        position: absolute;
        top: 0;
        right: 0;
        color: white;
        width: 1.8rem;
        height: 1.8rem;
        opacity: 0;
        visibility: hidden;
        transition: 300ms;
    }

    &:hover {
        .editable-icon {
            visibility: visible;
            opacity: 1;
        }
    }
}

.tree-root .tree-branch {
    overflow: unset !important;
}
</style>
