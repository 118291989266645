<template>
	<section class="position-relative px-4 pb-3">
		<section class="row mt-4">
			<div v-if="actualIsTrial" class="col-12 alert alert-info text-left">
				Obrigado por experimentar nossa plataforma!
				<b class="font-weight-bold">Seu período de teste chegou ao fim</b>
				. Para continuar aproveitando todos os recursos e benefícios, escolha um dos planos
				abaixo. Se precisar de ajuda para escolher o melhor plano para você, nossa equipe de
				suporte está à disposição!
			</div>
			<div v-else-if="trialPlan" class="col-12 alert alert-info text-left">
				Estamos felizes em tê-lo conosco. Para começar, você pode
				<b>
					escolher um dos nossos planos ou experimentar nossa plataforma gratuitamente por
					{{ isTrialDuration }} dias
				</b>
				. Aproveite este período de teste para explorar todos os recursos e descobrir como
				podemos ajudar você a alcançar seus objetivos. Se precisar de ajuda para escolher o
				melhor plano para você, nossa equipe de suporte está à disposição!
			</div>
			<div v-else class="col-12 alert alert-info text-left">
				Estamos felizes em tê-lo conosco. Para começar, você pode
				<b>escolher um dos nossos planos</b>
				e aproveitar todos os recursos que oferecemos para ajudá-lo a alcançar seus
				objetivos. Se precisar de ajuda para escolher o melhor plano para você, nossa equipe
				de suporte está à disposição!
			</div>

			<div class="pt-2 px-4 col-12">
				<plan-carousel
					:plans="plans"
					:loading="loading"
					@chosen="submit"
					:selected-plan-id="user.plan_id ?? undefined"
					:selected-plan-type="user.plan_type ?? undefined"
				/>
			</div>
		</section>
	</section>
</template>

<script>
import { isTrialDuration } from "@/api/constants";
import { currencyFormatter, planTypesSize } from "@/api/constants";
import api from "@/api";
import PlanCarousel from "@/views/components/PlanCarousel.vue";

export default {
	components: {
		PlanCarousel
	},
	data() {
		return {
			loading: false,
			plans: [],
			isTrialDuration
		};
	},
	created() {
		this.getPlans();
	},
	computed: {
		actualIsTrial() {
			if (this.user.plan_id == null) return false;

			if ("is_trial" in this.user.plan && this.user.plan.is_trial) return true;

			return false;
		},
		trialPlan() {
			const plans = this.plans.filter((p) => p.is_trial);

			return plans.length > 0 ? plans[0] : null;
		}
	},
	methods: {
		toast(message, type = "danger") {
			this.$bvToast.toast(message, {
				title: "Planos",
				autoHideDelay: 2000,
				variant: type
			});
		},
		submit(plan, type) {
			let html = `Tem certeza que deseja contratar o plano <b>${plan.name}</b> `;
			const size = planTypesSize[type];
			const price = currencyFormatter.format(plan.value);

			if (size <= 1) {
				html += `por ${price} mensais?`;
			} else {
				html += `por ${price} mensais durante ${size} meses?`;
			}

			html += ` O valor será debitado integralmente ao início de cada ciclo poderá ser cancelado a qualquer momento.`;

			const h = this.$createElement;
			const node = h("div", { domProps: { innerHTML: html } });

			this.$bvModal
				.msgBoxConfirm(node, {
					title: "Escolher plano",
					okTitle: "Sim",
					cancelTitle: "Cancelar",
					centered: true
				})
				.then((value) => {
                    if (value) {
                        this.loading = true;
                        api.post("/me/plan", { plan_id: plan._id, type })
                            .then((response) => {
                                const { message, type, errors } = response.data;

                                if (type != "success") {
                                    const msg = errors && errors.length > 0 ? errors[0] : message;

                                    this.toast(msg, "danger");
                                } else {
                                    this.$store.dispatch("user/loadSession").then(() =>
                                        setTimeout(() => {
                                            try {
                                                this.$router.replace({
                                                    name: this.$route.name,
                                                    params: { tab: "numeros" }
                                                });
                                                if (plan.is_trial) {
                                                    this.$root.$bvModal
                                                        .msgBoxOk('Cadastre um número de disparo para usar as nossas funcionalidades!', {
                                                            title: "Sucesso!",
                                                            okTitle: "Entendi",
                                                            centered: true
                                                        })
                                                }
                                            } catch (error) {
                                                // eslint-disable-next-line no-undef
                                                if (!(error instanceof NavigationDuplicated)) {
                                                    throw error;
                                                }
                                            }
                                        }, 100)
                                    );
                                }
                            })
                            .finally(() => (this.loading = false));
                    }
				});
		},
		getPlans() {
			this.loading = true;
			api.get("/plans")
				.then((res) => {
					const { body, type } = res.data;

					if (type === "success") {
						return (this.plans = body);
					}

					throw res;
				})
				.catch((err) => {
					let message = "Não foi possível carregar planos.";

					if (err.response) message = err.response.data.message;

					this.toast(message);
				})
				.finally(() => (this.loading = false));
		}
	}
};
</script>

<style scoped lang="scss">
.card-list {
	overflow-x: auto;

	.card {
		min-width: 200px;

		.card-body {
			min-width: 200px;
		}

		ul {
			margin-left: -20px;
		}
	}
}
</style>
