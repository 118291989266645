
import { macroList } from '@/api/constants.js';

const props = {
    index: {
        type: Number,
        default: () => null
    },
    readOnly: {
        type: Boolean,
        default: () => false
    },
    allowAttachments: {
        type: Boolean,
        default: () => false
    },
    maxAttachments: {
        type: Number,
        default: () => 2
    },
    showDelete: {
        type: Boolean,
        default: () => true
    },
    showToolbar: {
        type: Boolean,
        default: () => true
    },
    showText: {
        type: Boolean,
        default: () => true
    },
    showTitle: {
        type: Boolean,
        default: () => true
    },
    data : {
        type: Object,
        default: () => {
            return {
                title: 'Adicionar título',
                text: 'Adicionar mensagem',
                attachments: [],
                synonyms: [
                    {
                        sentence: 'Teste',
                        synonyms: ['Testando'],
                        color: '#FF0000'
                    }
                ]
            }
        }
    },
    macroList: {
        type: Array,
        default: () => macroList
    }
}

export default props;
