<template>
    <div :id="id">
        <label v-if="hasPreview" role="button" class="m-0" :for="inputId">
            <slot v-if="!loading" name="preview">
                <div
                    class="m-0 rounded pick-image d-flex align-items-center justify-content-center position-relative"
                >
                    <div
                        v-if="!campaign.creative && editable"
                        class="d-flex align-items-center"
                    >
                        <span class="circle-icon mr-3">+</span>
                        <span>Adicionar criativo</span>
                    </div>

                    <img
                        v-if="campaign.creative"
                        :src="imageSrc(campaign.creative)"
                        class="preview-image rounded"
                    >

                    <span
                        v-if="campaign.creative && editable"
                        class="close-button d-flex justify-content-center align-items-center border rounded-circle"
                        @click.prevent.stop="campaign.creative = null"
                    >
                            <font-awesome-icon icon="fa-solid fa-close" size="sm"/>
                        </span>
                </div>
            </slot>
            <div
                v-else
                class="m-0 rounded pick-image d-flex flex-column align-items-center justify-content-center position-relative"
            >
                <span class="spinner-border my-2"></span>
                <small>Fazendo upload da mídia...</small>
            </div>
        </label>
        <input v-if="!loading" :id="inputId" type="file" accept=".jpg,.jpeg,.png,.mp4,.3gpp,.pdf" :disabled="disabled" hidden @change="handleInputFile">

        <b-modal v-model="crop" title="Recortar" class="position-relative">
            <loading v-if="loading"></loading>
            <div class="cropper">
                <vue-cropper
                    ref="cropper"
                    :src="cropSrc"
                    v-bind="options"
                ></vue-cropper>
            </div>
            <template #modal-footer="{ cancel }">
                <div class="d-flex align-items-center jusitfy-content-end">
                    <button :disabled="loading" @click="cancel" class="btn btn-secondary mx-2">Cancelar</button>
                    <button :disabled="loading" @click="save" class="btn btn-primary">Recortar</button>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
import { BModal } from 'bootstrap-vue';
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import Loading from '@/views/components/Loading';
import api from '@/api';
import { acceptedMimes } from '@/api/constants';
import { uuid } from 'vue-uuid';

export default {
    components: {
        BModal,
        VueCropper,
        Loading
    },
    props: {
        src: {
            type: String
        },
        options: {
            type: Object
        },
        disabled: {
            type: Boolean,
            default: () => false
        },
        hasPreview: {
            type: Boolean,
            default: () => true
        },
        id: {
            type: String,
            default: () => uuid.v4()
        },
        inputId: {
            type: String,
            default: 'resizable-file-input'
        },
    },
    emits: ['input'],
    data() {
        return {
            previewSrc: this.$props.src,
            crop: false,
            cropSrc: null,
            loading: false
        }
    },
    watch: {
        src(value) {
            this.previewSrc = value;
        }
    },
    computed: {
        preview() {
            if(this.previewSrc != null) return this.previewSrc;
            if(this.$props.src != null) return this.$props.src;
            return '/assets/imgs/default-image.jpg';
        }
    },
    methods: {
        uploadTmpFile(file) {
            const formData = new FormData();
            const ext = file.name.split('.').pop();
            
            if(acceptedMimes.image.includes(ext)) formData.append('image', file);
            if(acceptedMimes.video.includes(ext)) formData.append('video', file);
            if(acceptedMimes.document.includes(ext)) formData.append('file', file);
            
            this.loading = true;
            api.post('/files', formData)
            .then(res => {
                const {message, body, type} = res.data;

                if(type == 'success') {
                    return this.$emit('input', body);
                }
                this.$swal('Erro ao processar mídia', message, 'error');
            })
            .catch(e => {
                let message = 'Não foi possível fazer o upload da mídia.';
                if(e.response) {
                    message = e.response.data.message;
                }
                this.$swal('Erro ao processar mídia', message, 'error');
            })
            .finally(() => this.loading = false)
        },
		save() {
            this.loading = true;
			const canvas = this.$refs.cropper.getCroppedCanvas();

            try {
                this.previewSrc = canvas.toDataURL();
                canvas.toBlob(blob => {
                    const file = new File([blob], "image.png");
                    this.crop = false;
                    this.loading = false;
                    this.cropSrc = null;
                    this.uploadTmpFile(file);
                }, 'image/jpeg', 0.9);
            } catch(e) {
                console.log(e)
                this.crop = false;
                this.loading = false;
                this.$swal('Erro ao recortar imagem', 'Não foi possível recortar imagem.', 'error');
            }
		},
        handleInputFile(event) {
            const accepted = Object.values(acceptedMimes).flat();

            if (!(event.target.files && event.target.files[0])) return;

            const file = event.target.files[0];
            event.target.value = null;
            const ext = file.name.split('.').pop();

            if(!accepted.includes(ext)) {
                return this.$swal('Formato não suportado', `O formato do arquivo não é suportado. [${accepted.join(', ')}]`, 'error');
            }

            if(acceptedMimes.image.includes(ext) && file.size && Number(file.size) / (1024 * 1024) > 5) {
                return this.$swal('Arquivo muito grande', `Somente imagens de até 5 MB são aceitos.`, 'error');
            }
            if(acceptedMimes.video.includes(ext) && file.size && Number(file.size) / (1024 * 1024) > 15) {
                return this.$swal('Arquivo muito grande', `Somente vídeos de até 15 MB são aceitos.`, 'error');
            }
            if(acceptedMimes.document.includes(ext) && file.size && Number(file.size) / (1024 * 1024) > 5) {
                return this.$swal('Arquivo muito grande', `Somente arquivos de até 5 MB são aceitos.`, 'error');
            }

            if(acceptedMimes.image.includes(ext)) {
                var reader = new FileReader();
    
                reader.onload = (e) => {
                   this.cropSrc = e.target.result;
                   this.crop = true;
                }
    
                return reader.readAsDataURL(file);
            }
            this.uploadTmpFile(file);
        }
    }
}
</script>

<style lang="scss" scoped>
    .cropper {
        max-height: 80vh;
        width: 100%;
        height: 100%;
        overflow-y: auto;
    }
</style>
